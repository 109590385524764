import { store } from '@/store/store';
import { mediaCitasano } from '@/shared/dtos/mediaCitasano';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'mediaCitasanoModule',
    store,
    dynamic: true
})
class mediaCitasanoModule extends VuexModule {
    public mediaCitasanos: mediaCitasano[] = [];

    @Action({ commit: 'onGetmediaCitasanos' })
    public async getmediaCitasanos() {
        return await ssmHttpService.get(API.DashMediaCitasAno,null,false);
    }

    @Mutation
    public onGetmediaCitasanos(res: mediaCitasano[]) {
        this.mediaCitasanos = res ? res.map((x) => new mediaCitasano(x)) : [];
    }

}
export default getModule(mediaCitasanoModule);