









import { Component, Vue } from "vue-property-decorator";
import mediaCitasano from "@/store/modules/Dashboards/mediaCitasano-module";
import VueApexCharts from "vue-apexcharts";
import { DashColors } from "./Dashcolors";
@Component({
  components: {
    VueApexCharts
  }
})
export default class MediaCitasAno extends Vue {
  public created() {
    mediaCitasano.getmediaCitasanos();
  }
  public get datos() {
    //hay que poner un try sino no te lo pintae;
    let dt: any[] = [];
    try {
      for (let i = 0; i < mediaCitasano.mediaCitasanos.length; i++) {
        const element = mediaCitasano.mediaCitasanos[i];
        dt.push({ name: element.ano, data: [element.media] });
      }
    } catch (error) {}
    return dt;
  }

  public get categories() {
    //hay que poner un try sino no te lo pinta
    let dt: number[] = [];
    try {
      for (let i = 0; i < mediaCitasano.mediaCitasanos.length; i++) {
        const element = mediaCitasano.mediaCitasanos[i];
        dt.push(element.ano);
      }
    } catch (error) {}
    return dt;
  }
  public get chartOptions() {
    return {
      grid: {
        show: false,
        padding: {
          left: 10,
          right: 10
        }
      },
      /*states: {
        hover: {
          filter: "gradient"
        }
      },*/
      colors: DashColors.Uniccolors,
      xaxis: {
        type: "date",
        categories: this.categories
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
          distributed: true,
          endingShape: "rounded" // Deprecated
          // borderRadius: '25px', // Coming Soon
        }
      },
      tooltip: {
        x: { show: false }
      }
    };
  }
}
